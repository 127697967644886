import { render, staticRenderFns } from "./Licenciamento.vue?vue&type=template&id=fa20e65c&scoped=true"
import script from "./Licenciamento.vue?vue&type=script&lang=js"
export * from "./Licenciamento.vue?vue&type=script&lang=js"
import style0 from "./Licenciamento.vue?vue&type=style&index=0&id=fa20e65c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa20e65c",
  null
  
)

export default component.exports